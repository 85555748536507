// This is an autogenerated file. See the routes package for more information.
export default [
  {
    "hash": "pcc-geoff-s-suburban-london-social",
    "title": "Geoff's Suburban London Social",
    "description": "Touching the countryside only for cold days",
    "distance": 61,
    "vert": 576,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34978326",
    "gpx": "https://ridewithgps.com/routes/34978326.gpx",
    "fit": "https://ridewithgps.com/routes/34978326.fit",
    "map": "/routes/34978326.png"
  },
  {
    "hash": "pcc-cx-south-london-loop",
    "title": "CX South London Loop",
    "description": "Big day out on the cross and gravel bikes! Once you reach Wimbledon you barely ride on a road again until you're back in London. Find refreshment options at the Box Hill café, or if it's too busy, it's only a few km of flat bridleways to the Cricketer pub in Mogador.",
    "distance": 113,
    "vert": 1163,
    "type": "off-road",
    "link": "https://ridewithgps.com/routes/34684178",
    "gpx": "https://ridewithgps.com/routes/34684178.gpx",
    "fit": "https://ridewithgps.com/routes/34684178.fit",
    "map": "/routes/34684178.png"
  },
  {
    "hash": "pcc-surrey-hills",
    "title": "Surrey Hills",
    "description": "A pretty hefty 150k/1800m, which takes in several large lumps in the Surrey Hills. With café stop options before at Tanhouse Farm Shop or after at Box Hill to keep you fueled.",
    "distance": 150,
    "vert": 1863,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684114",
    "gpx": "https://ridewithgps.com/routes/34684114.gpx",
    "fit": "https://ridewithgps.com/routes/34684114.fit",
    "map": "/routes/34684114.png"
  },
  {
    "hash": "pcc-peckham-to-rye",
    "title": "Peckham to Rye",
    "description": "Long but pretty flat once you've left the North Downs, including a café stop at the legendary Teapot Island. Grab some fish and chips at Rye to scoff on the train back via Ashford.",
    "distance": 123,
    "vert": 1214,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684089",
    "gpx": "https://ridewithgps.com/routes/34684089.gpx",
    "fit": "https://ridewithgps.com/routes/34684089.fit",
    "map": "/routes/34684089.png"
  },
  {
    "hash": "pcc-social-toys-ide",
    "title": "Social+ Toys & Ide",
    "description": "A classic training ride. Café stop at Ide Hill Village Shop to recover after two tough climbs in a row.",
    "distance": 81,
    "vert": 1208,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684057",
    "gpx": "https://ridewithgps.com/routes/34684057.gpx",
    "fit": "https://ridewithgps.com/routes/34684057.fit",
    "map": "/routes/34684057.png"
  },
  {
    "hash": "pcc-social-school-loop",
    "title": "Social+ School Loop",
    "description": "One of many School Loop variants - after the beautiful Woldingham School and the punishing Gangers Hill climb after, a quick zoom down Pilgrims Way for a coffee at the VeloBarn before climbing back up one of Hogstrough, Brasted or Sundridge.",
    "distance": 80,
    "vert": 973,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684043",
    "gpx": "https://ridewithgps.com/routes/34684043.gpx",
    "fit": "https://ridewithgps.com/routes/34684043.fit",
    "map": "/routes/34684043.png"
  },
  {
    "hash": "pcc-social-knatts-valley",
    "title": "Social+ Knatts Valley",
    "description": "A Peckham CC classic - out to the glorious roads of the well hidden Knatt's Valley. Coffee stop at the charming but antiquated Battle of Britain Museum in Shoreham - bring cash!",
    "distance": 87,
    "vert": 1030,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684027",
    "gpx": "https://ridewithgps.com/routes/34684027.gpx",
    "fit": "https://ridewithgps.com/routes/34684027.fit",
    "map": "/routes/34684027.png"
  },
  {
    "hash": "pcc-early-morning-loop",
    "title": "Early Morning Loop",
    "description": "In summer we often meet at 6:15am at the Library for a steady leg spinner, back in Peckham for ~8:15 to give people time to shower and get to work. Here's a nice morning route that cuts out a few hills. Careful on the descent to Addington, and then watch out for trams when crossing the dual carriageway!",
    "distance": 53,
    "vert": 561,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34683922",
    "gpx": "https://ridewithgps.com/routes/34683922.gpx",
    "fit": "https://ridewithgps.com/routes/34683922.fit",
    "map": "/routes/34683922.png"
  },
  {
    "hash": "pcc-to-kingdom",
    "title": "to Kingdom",
    "description": "A 100k round trip to a Peckham CC café stop favourite - the absolutely stunning woodland café KINGDOM out in Penshurst.",
    "distance": 103,
    "vert": 1420,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684072",
    "gpx": "https://ridewithgps.com/routes/34684072.gpx",
    "fit": "https://ridewithgps.com/routes/34684072.fit",
    "map": "/routes/34684072.png"
  },
  {
    "hash": "pcc-to-the-archive",
    "title": "to the Archive",
    "description": "The Archive cafe is at 51km",
    "distance": 110,
    "vert": 1164,
    "type": "road",
    "link": "https://ridewithgps.com/routes/43042217",
    "gpx": "https://ridewithgps.com/routes/43042217.gpx",
    "fit": "https://ridewithgps.com/routes/43042217.fit",
    "map": "/routes/43042217.png"
  },
  {
    "hash": "pcc-social-crowhurst",
    "title": "Social+ Crowhurst",
    "description": "Just under 90k, some nice country lanes and a bit quieter than some of the other roads further west",
    "distance": 87,
    "vert": 1030,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34684012",
    "gpx": "https://ridewithgps.com/routes/34684012.gpx",
    "fit": "https://ridewithgps.com/routes/34684012.fit",
    "map": "/routes/34684012.png"
  },
  {
    "hash": "peckham-cc-social",
    "title": "Peckham CC Social",
    "description": "This is the route taken on our Saturday Social Spins. Some minor variants exist but this our typical social route\n\nIt generally ends back in Peckham with a coffee stop at The Hill Station, on telegraph hill, or a pint at the Brick Brewery.",
    "distance": 61,
    "vert": 765,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34683876",
    "gpx": "https://ridewithgps.com/routes/34683876.gpx",
    "fit": "https://ridewithgps.com/routes/34683876.fit",
    "map": "/routes/34683876.png"
  },
  {
    "hash": "pcc-hell-of-the-ashdown-2022-",
    "title": "Hell of the Ashdown (2022)",
    "description": "The classic February sportive, including riding out from Peckham and back to make it up to a 100 mile day",
    "distance": 166,
    "vert": 2243,
    "type": "road",
    "link": "https://ridewithgps.com/routes/31804969",
    "gpx": "https://ridewithgps.com/routes/31804969.gpx",
    "fit": "https://ridewithgps.com/routes/31804969.fit",
    "map": "/routes/31804969.png"
  },
  {
    "hash": "pcc-to-whitstable",
    "title": "to Whitstable",
    "description": "Commonly ridden on a may bank holiday, or as our end of season social on the last nice weekend in September. Café stop half way at The Little Old Tea House in Maidstone. Fish and chips on the beach after, then squeeze on to the train back to Denmark Hill",
    "distance": 115,
    "vert": 1286,
    "type": "road",
    "link": "https://ridewithgps.com/routes/28690903",
    "gpx": "https://ridewithgps.com/routes/28690903.gpx",
    "fit": "https://ridewithgps.com/routes/28690903.fit",
    "map": "/routes/28690903.png"
  },
  {
    "hash": "pcc-cx-social",
    "title": "CX Social",
    "description": "Intended for gravel and CX bikes!",
    "distance": 62,
    "vert": 758,
    "type": "off-road",
    "link": "https://ridewithgps.com/routes/34684204",
    "gpx": "https://ridewithgps.com/routes/34684204.gpx",
    "fit": "https://ridewithgps.com/routes/34684204.fit",
    "map": "/routes/34684204.png"
  },
  {
    "hash": "pcc-cx-social-alt",
    "title": "CX Social Alt",
    "description": "CX/Gravel bikes!",
    "distance": 52,
    "vert": 461,
    "type": "off-road",
    "link": "https://ridewithgps.com/routes/34684188",
    "gpx": "https://ridewithgps.com/routes/34684188.gpx",
    "fit": "https://ridewithgps.com/routes/34684188.fit",
    "map": "/routes/34684188.png"
  },
  {
    "hash": "pcc-cx-darent-thames-path",
    "title": "CX Darent & Thames Path",
    "description": "Gravel/CX ride - Lumpy and muddy out, flat and smoother back along the Thames Path",
    "distance": 82,
    "vert": 470,
    "type": "off-road",
    "link": "https://ridewithgps.com/routes/34684164",
    "gpx": "https://ridewithgps.com/routes/34684164.gpx",
    "fit": "https://ridewithgps.com/routes/34684164.fit",
    "map": "/routes/34684164.png"
  },
  {
    "hash": "pcc-ally-pally",
    "title": "Ally Pally",
    "description": "This route takes in the Royal Parks - St James, Hyde Park and Regents’ Park, before heading up to the hills of North London. You’ll ride through Hampstead Heath and then up to Alexandra Palace for some spectacular views over London.",
    "distance": 55,
    "vert": 375,
    "type": "road",
    "link": "https://ridewithgps.com/routes/35102689",
    "gpx": "https://ridewithgps.com/routes/35102689.gpx",
    "fit": "https://ridewithgps.com/routes/35102689.fit",
    "map": "/routes/35102689.png"
  },
  {
    "hash": "pcc-thames-path-east",
    "title": "Thames Path East",
    "description": "A ride which takes you along the south side of the river on the Thames Path from Greenwich Peninsula past the Thames Barrier and out to Abbey Wood, Bexley. Here you’ll find a couple of interesting places to stop - the first is a short climb of the twisting paths of manmade Gallions Hill, and shortly afterwards Lesnes Abbey, a Grade II Norman ruin. Suitable for all types of bikes, although there are some dirt paths. ",
    "distance": 43,
    "vert": 96,
    "type": "road",
    "link": "https://ridewithgps.com/routes/35012528",
    "gpx": "https://ridewithgps.com/routes/35012528.gpx",
    "fit": "https://ridewithgps.com/routes/35012528.fit",
    "map": "/routes/35012528.png"
  },
  {
    "hash": "pcc-every-london-bridge",
    "title": "Every London Bridge",
    "description": "Travelling east to west, you’ll ride over all of London’s Bridges starting with Tower Bridge and ending with Hampton Court Bridge. \n\nNB Hammersmith Bridge is closed but this route has been edited to account for that - it means that if you want to tick off Fulham Bridge you'll have to go over it and come back to the route.\n",
    "distance": 83,
    "vert": 314,
    "type": "road",
    "link": "https://ridewithgps.com/routes/35034777",
    "gpx": "https://ridewithgps.com/routes/35034777.gpx",
    "fit": "https://ridewithgps.com/routes/35034777.fit",
    "map": "/routes/35034777.png"
  },
  {
    "hash": "pcc-south-london-alpine",
    "title": "South London Alpine",
    "description": "Loops around Crystal Palace. Check at four boroughs for coffee in the Crystal Palace Triangle.",
    "distance": 34,
    "vert": 489,
    "type": "road",
    "link": "https://ridewithgps.com/routes/35012502",
    "gpx": "https://ridewithgps.com/routes/35012502.gpx",
    "fit": "https://ridewithgps.com/routes/35012502.fit",
    "map": "/routes/35012502.png"
  },
  {
    "hash": "pcc-hills-of-se-london",
    "title": "Hills of SE London",
    "description": "Trying to tie together as many hills as possible whilst staying close to SE London. Start early to get clearer bike paths around Greenwich park/Blackheath. \n\nRecommended coffee stops at Big Bro Espresso (outside lewisham DLR station) and Four Boroughs (At the top of Anerley, in the Crystal Palace Triangle on the route)",
    "distance": 54,
    "vert": 668,
    "type": "road",
    "link": "https://ridewithgps.com/routes/35012501",
    "gpx": "https://ridewithgps.com/routes/35012501.gpx",
    "fit": "https://ridewithgps.com/routes/35012501.fit",
    "map": "/routes/35012501.png"
  },
  {
    "hash": "social-chelsfield",
    "title": "Social+ Chelsfield",
    "description": "A good summer day route, quite a lot of the lanes by Chelsfield village are quiet, but can be muddy and gravelly in the winter - so best save for warm days.",
    "distance": 73,
    "vert": 871,
    "type": "road",
    "link": "https://ridewithgps.com/routes/41733455",
    "gpx": "https://ridewithgps.com/routes/41733455.gpx",
    "fit": "https://ridewithgps.com/routes/41733455.fit",
    "map": "/routes/41733455.png"
  },
  {
    "hash": "l2b2l",
    "title": "L2B2L",
    "description": "A big day out, but a great challenge of a ride.  Watch out for the very steep Cob Lane (c. 60km) and busier roads around Turner's Hill/Crawley Down on the way home",
    "distance": 196,
    "vert": 2462,
    "type": "road",
    "link": "https://ridewithgps.com/routes/41733447",
    "gpx": "https://ridewithgps.com/routes/41733447.gpx",
    "fit": "https://ridewithgps.com/routes/41733447.fit",
    "map": "/routes/41733447.png"
  },
  {
    "hash": "social-cold-weather",
    "title": "Social Cold Weather",
    "description": "One for a colder day, these lanes get sun on them and tend to warm up faster than around Tatsfield / Beddlestead. Take care with the sharp turn Berry's Hill / Berry's Green Road",
    "distance": 63,
    "vert": 708,
    "type": "road",
    "link": "https://ridewithgps.com/routes/41733435",
    "gpx": "https://ridewithgps.com/routes/41733435.gpx",
    "fit": "https://ridewithgps.com/routes/41733435.fit",
    "map": "/routes/41733435.png"
  },
  {
    "hash": "social-bear-no-beddlestead",
    "title": "Social Bear No Beddlestead",
    "description": "Slight twist on the way out via Penge High Street, up Nash Lane to join the usual route, go past the White Bear and up to the top of the downs and skip past Tatsfield Village. Back the usual sort of way.",
    "distance": 64,
    "vert": 741,
    "type": "road",
    "link": "https://ridewithgps.com/routes/34823107",
    "gpx": "https://ridewithgps.com/routes/34823107.gpx",
    "fit": "https://ridewithgps.com/routes/34823107.fit",
    "map": "/routes/34823107.png"
  },
  {
    "hash": "pcc-reverse-beddlestead",
    "title": "Reverse Beddlestead",
    "description": "Mostly a reverse version of the social with some different ways to get out of Peckham and back to coffee.\n",
    "distance": 65,
    "vert": 707,
    "type": "road",
    "link": "https://ridewithgps.com/routes/33741370",
    "gpx": "https://ridewithgps.com/routes/33741370.gpx",
    "fit": "https://ridewithgps.com/routes/33741370.fit",
    "map": "/routes/33741370.png"
  },
  {
    "hash": "social-school-featherbed-return",
    "title": "Social School Featherbed Return",
    "description": "School loop with a return via Featherbed Lane, two sharp climbs on Gangers and Spout Hills, for those who love a sharp pointy type ride!",
    "distance": 68,
    "vert": 852,
    "type": "road",
    "link": "https://ridewithgps.com/routes/30493729",
    "gpx": "https://ridewithgps.com/routes/30493729.gpx",
    "fit": "https://ridewithgps.com/routes/30493729.fit",
    "map": "/routes/30493729.png"
  },
  {
    "hash": "social-via-shire-lane",
    "title": "Social via Shire Lane",
    "description": "Avoids College Road gentle start with some hills in Chelsfield. \nCross the A21 at lights after coming down from World's End Lane/Chelsfield Village.",
    "distance": 63,
    "vert": 664,
    "type": "road",
    "link": "https://ridewithgps.com/routes/31847012",
    "gpx": "https://ridewithgps.com/routes/31847012.gpx",
    "fit": "https://ridewithgps.com/routes/31847012.fit",
    "map": "/routes/31847012.png"
  },
  {
    "hash": "social-pilgrim-star",
    "title": "Social+ Pilgrim & Star",
    "description": "Along Pilgrims Way and up Star Hill, back through Knockholt Village - just a little longer than a regular ride out.  Quite good in winter since avoids some more gravelly lanes.",
    "distance": 73,
    "vert": 943,
    "type": "road",
    "link": "https://ridewithgps.com/routes/41733468",
    "gpx": "https://ridewithgps.com/routes/41733468.gpx",
    "fit": "https://ridewithgps.com/routes/41733468.fit",
    "map": "/routes/41733468.png"
  },
  {
    "hash": "social-goathurst",
    "title": "Social+ Goathurst",
    "description": "A fairly long route, but with minimal climbs, some good fast lanes through Otford, Pilgrims Ways and Ightham.  Add 10km and add in either Baileys, Yorks, or Toys Hill for extra challenge.",
    "distance": 97,
    "vert": 1189,
    "type": "road",
    "link": "https://ridewithgps.com/routes/41750518",
    "gpx": "https://ridewithgps.com/routes/41750518.gpx",
    "fit": "https://ridewithgps.com/routes/41750518.fit",
    "map": "/routes/41750518.png"
  }
]
  